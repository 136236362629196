export function initGreenhouse(cb) {
    const oScript = document.createElement("script");
    oScript.type = "text\/javascript";
    oScript.src = 'https://boards.greenhouse.io/embed/job_board/js?for=clickbooth';
    oScript.id = 'greenhouse';
    document.body.appendChild(oScript);
    oScript.onload = function () {
        $('#grnhse_iframe').ready(function () {
            Grnhse.Settings.scrollOnLoad=  false;
            Grnhse.Iframe.load();
            setTimeout(()=> cb(this),100);
        })
    };
}