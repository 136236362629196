import {AppController} from './AppController.js';
import {ContactController} from "./ContactController";
import {AdvertiserRegistrationController} from "./register/AdvertiserRegistrationController";
import {AffiliateRegistrationController} from "./register/AffiliateRegistrationController";
import {initGreenhouse} from './greenhouse'
import {AgencyPartnerRegistrationController} from "./register/AgencyPartnerRegistrationController";
angular.module('app')
    .controller('AppController', AppController)
    .controller('ContactController', ContactController)
    .controller('AffiliateRegistrationController', AffiliateRegistrationController)
    .controller('AdvertiserRegistrationController', AdvertiserRegistrationController)
    .controller('AgencyPartnerRegistrationController', AgencyPartnerRegistrationController);

//detecting useragent
var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);

(function() {
    function scrolltotarget(target) {
        var $target = $(target);
        var $target_top = $target.offset().top - 45;
        $("html, body").animate({
            'scrollTop': $target_top
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    }

    function getCookie(cookie) {
        var allCookies = {};

        document.cookie.split("; ").map(function(item) {
            var arr = item.split('=');
            allCookies[arr[0]] = arr[1];
            return item;
        })

        if(cookie) {
            return isUndefinedOrNull(allCookies[cookie]) ? null : allCookies[cookie];
        }

        return allCookies;
    }

    $(document).ready(function () {
        $('a[parent-url]').on('click', function (e) {
            var a = $(this).attr('parent-url');
            var full = window.location.origin+window.location.pathname;
            if(buildUrl(a) !== buildUrl(full)){
                return window.location.replace(a + this.hash)
            }
            e.preventDefault();
            scrolltotarget(this.hash);
        });
        $('[section-name]').each((i,e)=>{
            setTimeout(()=>{
                $(e).attr('id',$(e).attr('section-name'))
            },1)
        })
    });

    //Header Scroll animations
    var myElement = document.querySelector("header");
    if(!isUndefinedOrNull(myElement)) {
        // construct an instance of Headroom, passing the element
        var headroom = new Headroom(myElement);
        // initialise
        headroom.init();
    }

    //homepage intro - cover parallax
    var cover_image = $('.cover-image');
    $(window).scroll(function () {
        var st = this.pageYOffset;
        cover_image.css({'transform' : 'translate3d(0, ' + (st*.3) + 'px, 0)'});
    });

    //<img> Elements inject to svg
    var mySVGsToInject = document.querySelectorAll('img.inject-me');
    // Do the injection
    SVGInjector(mySVGsToInject);

    //particle animation config
    $(document).ready(function () {
        if ($('main.page-home').length > 0 && $(window).width() > 1025)  {
            particlesJS("particles-js", {
                particles: {
                    number: {value: 80, density: {enable: true, value_area: 800}},
                    color: {value: "#D8D8D8"},
                    shape: {
                        type: "circle",
                        stroke: {width: 0, color: "#D8D8D8"},
                        polygon: {nb_sides: 5},
                        image: {src: "img/github.svg", width: 100, height: 100}
                    },
                    opacity: {
                        value: 0.5,
                        random: false,
                        anim: {enable: false, speed: 1, opacity_min: 0.1, sync: false}
                    },
                    size: {
                        value: 3,
                        random: true,
                        anim: {enable: false, speed: 40, size_min: 0.1, sync: false}
                    },
                    line_linked: {
                        enable: true,
                        distance: 150,
                        color: "#D8D8D8",
                        opacity: 0.4,
                        width: 1
                    },
                    move: {
                        enable: true,
                        speed: 5,
                        direction: "none",
                        random: false,
                        straight: false,
                        out_mode: "out",
                        bounce: false,
                        attract: {enable: false, rotateX: 600, rotateY: 1200}
                    }
                },
                interactivity: {
                    detect_on: "canvas",
                    events: {
                        onhover: {enable: true, mode: "grab"},
                        onclick: {enable: false, mode: "push"},
                        resize: true
                    },
                    modes: {
                        grab: {distance: 203.7962037962038, line_linked: {opacity: 1}},
                        bubble: {
                            distance: 400,
                            size: 40,
                            duration: 2,
                            opacity: 8,
                            speed: 3
                        },
                        repulse: {distance: 200, duration: 0.4},
                        push: {particles_nb: 4},
                        remove: {particles_nb: 2}
                    }
                },
                retina_detect: true
            });
        }
        if($('main.page-company').length){
            initGreenhouse(()=>{
                var target = '#careers'
                if (window.location.hash === target) {
                    setTimeout(()=> scrolltotarget(target),100);
                }
            });
        }
        //company page tabs
        $(".tabs-content-item").hide();
        $(".tabs-content-item:first").show();
        var $tablink = $(".tabs-nav-item a");
        $tablink.click(function(event){
            event.preventDefault();
            $(this).parent().addClass("active");
            $(this).parent().siblings().removeClass("active");
            var tab = $(this).attr("href");
            $(".tabs-content-item").not(tab).css("display", "none");
            $(tab).fadeIn();
        });

        //mobile navigation trigger
        $('.mobile-nav-btn').click(function () {
            $(this).toggleClass('is-clicked');
            $('.mobile-nav').toggleClass('is-visible');
        });

        $('.mobile-nav').click(function () {
            $(this).removeClass('is-visible');
            $(".mobile-nav-btn").removeClass('is-clicked');
        });


        $('.accordion-tab').click(function () {
            var $this = $(this);
            if ($this.hasClass('open')) {
                $('.accordion-tab').removeClass('open');
                $('.accordion-content').stop(true).slideUp("fast");
                $('.expand').removeClass('turn');
                $this.removeClass('open');
                // $this.children('.expand').removeClass('turn');
                $this.next().stop(true).slideUp("fast");
            }
            else {
                $('.accordion-tab').removeClass('open');
                $('.accordion-content').stop(true).slideUp("fast");
                // $('.expand').removeClass('turn');

                $this.addClass('open');
                // $this.children('.expand').addClass('turn');
                $this.next().stop(true).slideDown("fast");
            }
        });

        if ($('.auth.register').length > 0)  {
            $(window).scroll(function() {
                var hT = $('[pull-header]').offset().top,
                    // hH = $('[pull-header]').outerHeight(),
                    wH = $(window).height(),
                    wS = $(this).scrollTop();
                if (wS > (hT-wH) && (hT > wS) && (wS+wH > hT)){
                    $('.auth-header').addClass('off')
                } else {
                    $('.auth-header').removeClass('off')
                }
            });
            $('.header-steps > .btn-step').click( function() {
                setTimeout(function(){
                    $("html, body").animate({ scrollTop: 0 }, 400);
                    return false;
                }, 10);
            });
        };

        var nonEuVisitors = $('#nonEuVisitors');

        if (nonEuVisitors.length && getCookie('consent_cookie') == null) {

            $('.privacy-banner').fadeIn();

            var d = new Date();
            d.setFullYear(d.getFullYear() + 5);
            document.cookie = "consent_cookie=accepted; expires=" + d + "; domain=" + nonEuVisitors.attr('value') + "; path=/";

            //call privacy consent api
            jQuery.ajax('/pingback_accepted', {
                'method' : 'GET'
            });

        }

        // check for privacy cookie
        if(getCookie('consent_cookie') == 'accepted') {
            tracking_fire();
        } else {
            angular.element('.privacy-banner').fadeIn();
        }

    });

})();








