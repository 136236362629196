export class AgencyPartnerRegistrationController {

    /* @ngInject */
    constructor($scope, config, AffiliateRegistrar, AdperioAffiliateRegistrar, $modal) {
        let ctrl_this = this;
        this.scope = $scope;
        this.modal = $modal;
        $scope.first_recaptcha_validation = true;
        $scope.recaptcha_checked = null;

        $scope.user = {
            agency_user: 1,
            user_type: 'affiliate',
            affiliate_subtype: "media_buyer",
            earning: "$0 - $4,999",
            payment_info: {
                address: "401 N Cattlemen Rd Ste.200",
                city: "Sarasota",
                country: "US",
                state: "FL",
                zip_code: "34232"
            },
            phone: "9414835555",
            referee_code: null,
            registration_info: {
                affiliate_traffic_type: "Both",
                description: "",
                email: {
                    list: "Owned",
                    size: "0 - 49,999",
                },
                heard_from: "Agency ATR Campaign",
                is_affiliate_network: "No",
                mobile_requirements: {
                    app_site_name: 1,
                    app_vertical: 1,
                    bundle_id: 1,
                    city_state_target: 1,
                    creative_id: 1,
                    idfa_gaid: 1,
                    impression_report: 1,
                },
                specific_offers: "",
                traffic_distribution: "Both",
                traffic_type: {
                    media_buyer: {
                        app_installs: 1,
                        contextual: 1,
                        display: 1,
                        native: 1,
                        other: 1,
                        pay_per_call: 1,
                        reward: 1,
                        search: 1,
                        seo: 1,
                        sms: 1,
                        social: 1,
                        survey: 1,
                    }
                }
            },
            step1_completed: true,
            toa: false,
            website: [
                {text: ""}
            ],
        }

        $scope.tmpUser = {
            type: 'affiliate'
        };
        $scope.errors = {
            messages: null
        };

        $scope.uploadedFiles = {
            files: []
        };

        $scope.countries = config.countries;
        $scope.states = config.states;

        $scope.step = 1;
        $scope.submited_step1 = false;

        $scope.application_id = null;
        $scope.account_request = null;
        $scope.login_forget = 1;

        $scope.lock_submit_till_response = false;

        $scope.hasError = function (ngModel) {
            if (typeof ngModel == 'undefined' || ngModel.$touched === false) {
                return false;
            }

            return ngModel.$invalid;
        };

        $scope.acc_executive_check = {
            checked: false
        };


        $scope.prevent_multiple_submits = function (lock){
            $scope.lock_submit_till_response = lock;
        };

        $scope.clearErrors = function() {
            $scope.errors.messages = null
        };

        $scope.registerAgencyPartner = function(hash){

            $scope.user.hashed_email = hash;
            $scope.prevent_multiple_submits(true);
            AffiliateRegistrar.register($scope.user, null, null, null)
                .success(function (response, status, headers, config) {
                    $scope.prevent_multiple_submits(false);
                    window.location.href = response.data.redirect_to;

                }).error(function (response) {
                $scope.prevent_multiple_submits(false);
                $scope.showErrors(response);
            });
        }
        let _this = this;
        $scope.showErrors = function(response) {
            $scope.errorMessage = null;
            angular.forEach(response, function (error) {
               $scope.errorMessage = error[0];
            })

            _this.setErrors($scope.errorMessage)
        };
    }

    openVideoModal(){
        this.modal({
            keyboard: false,
            templateUrl: '/auth/register/video-modal',
        });
    }

    openVideoModalV2(){
        this.modal({
            keyboard: false,
            templateUrl: '/auth/register/video-modal-v2',
        });
    }

    setErrors(error){
        $('#errors').text(error);
    }

}
