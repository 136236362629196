export class ContactController {

    /* @ngInject */
    constructor($scope, Contact) {
        $scope.imArray = [
            {id: 'advertisers', name: 'Marketer'},
            {id: 'affiliates', name: 'Partner'},
            {id: 'press', name: 'Press'},
            {id: 'hr', name: 'Prospective Team Member'}
        ];

        $scope.contactMessage = {};

        $scope.send = function (form) {
            $scope.successInfo = false;
            $scope.failureInfo = false;
            if (form.$valid) {
                var data = {
                    'full_name': $scope.contactMessage.fullName,
                    'company': $scope.contactMessage.company,
                    'email': $scope.contactMessage.email,
                    'skype': $scope.contactMessage.skype,
                    'send_to': $scope.contactMessage.selectedItem.id,
                    'message': $scope.contactMessage.message
                };
                Contact.save(data).fetching($scope, 'saving').then(function success() {
                    $scope.successInfo = true;
                    angular.forEach($scope.contactMessage, function (value, key) {
                        $scope.contactMessage[key] = '';
                    });
                    $scope.has_error = false;
                }, function error() {
                    $scope.failureInfo = true;
                    $scope.has_error = false;
                });
            } else {
                $scope.has_error = true;
            }
        };

        $scope.hideMessage = function (type) {
            if (type == 'success') {
                $scope.successInfo = false;
            } else {
                $scope.failureInfo = false;
            }
        };
    }

}

ContactController.$inject = ['$scope', 'Contact'];
