import amplitude from "amplitude-js";

export class AppController {

    /* @ngInject */
    constructor($scope, Newsletter, $state, JWTService, successfullyAddedDevice) {
// console.log(successfillyAddedDevice);

        this.successfullyAddedDevice = successfullyAddedDevice;
        this.scope = $scope;
        this.state = $state;
        this.user = config.user;
        let event = "JWT Token missing";
        let eventProperties = JWTService.getEventProperties("affiliate custom domain");

        /**
         * Checking if jwt token exists in cookies, if not then new token is created and stored in cookies
         */

         if(!isUndefinedOrNull(config.temp_token)) {
            let tempTokenData = {
                id: 1,
                token: config.temp_token,
                user: null,
                config: config
            };
            document.getElementById('two_factor_authentication').onload = function () {
                document.getElementById('two_factor_authentication').contentWindow.postMessage(tempTokenData, "*")
            };
        } else{
            if(!isUndefinedOrNull(config.user)) {
                amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY, "00" + config.user.id)
                amplitude.getInstance().logEvent(event, eventProperties);
                
                if (isUndefinedOrNull(config.password_expired)) {
                    JWTService.createJwtTokenAndReload('two_factor_authentication', 'token', this.user, this.state)
                }
            }

        }
        // this.successfullyAddedDevice.show();

        /**
         * end of check
         */
        // Event Listener for receiving messages from React application through iframe
        window.addEventListener("message", this.listener, false);
        // action for removing backdrop in case user go back on other page without closing modal
        // action triggered on scope destroy
        $scope.$on('$destroy', () => {
            window.removeEventListener("message", this.listener, false);
        });

        $scope.recognitionSlickConfig = {
            draggable: true,
            slidesToShow: 4,
            prevArrow: '<button class="slider-arrows prev"><i class="ti-angle-left"></i></button>',
            nextArrow: '<button class="slider-arrows next"><i class="ti-angle-right"></i></button>',
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 593,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        $scope.quotesSlickConfig = {
            draggable: true,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            prevArrow: false,
            nextArrow: false,
            speed: 350,
            dots: true
        };

        $scope.subscribe = function (form) {
            $scope.subSuccess = false;
            $scope.subFailure = false;
            if (form.$invalid) {
                $scope.sub_error = true;
            } else {
                var data = {
                    email: $scope.subscribeEmail
                };
                Newsletter.save(data).fetching($scope, 'subscribing').then(function success() {
                    $scope.subscribeEmail = '';
                    $scope.sub_error = false;
                    $scope.subSuccess = true;
                }, function failure(response) {
                    $scope.responseMessage = response.message;
                    $scope.sub_error = false;
                    $scope.subFailure = true;
                });
            }
        };

        $scope.hasError = function (ngModel) {
            if (typeof ngModel == 'undefined' || ngModel.$touched === false) {
                return false;
            }

            return ngModel.$invalid;
        };

        $scope.consent = function(domain) {
            if(domain !== false) {
                var d = new Date();
                d.setFullYear(d.getFullYear() + 5);
                document.cookie = "consent_cookie=accepted; expires=" + d + "; domain=." + domain + "; path=/";

                tracking_fire();

                //call privacy consent api
                $.ajax('/pingback_accepted', {
                    'method' : 'GET'
                });
            }

            angular.element('.privacy-banner').fadeOut();
        };

    };

    listener = (event) => {
        if(event.data.id === 8){
            window.location.replace(process.env.APP_URL + '/auth/logout')
        }
        if(event.data.id === 9){
            if (config.successfullyAddedDevice) {
                this.successfullyAddedDevice.show();
                this.successfullyAddedDevice.link(process.env.APP_URL + '/auth/' + event.data.data);

                var timeout = setTimeout(function() {
                    window.location.replace(process.env.APP_URL + '/auth/' + event.data.data)
                }, 3000);

                setTimeout(function() {
                    document.getElementById('authoriser').onclick = function() {
                        clearTimeout(timeout);
                    };
                }, 500);
            } else {
                window.location.replace(process.env.APP_URL + '/auth/' + event.data.data)
            }

        }

        if(event.data.id === 10){
            window.location.replace(event.data.data)
        }
    }

}

AppController.$inject = ['$scope', 'Newsletter', '$state', 'JWTService', 'successfullyAddedDevice'];
