(function () {
	angular
		.module('app')
		.service('Registrar', ['config', 'Resource', function (config, Resource) {
			var model = new Resource({
				url: config.base + '/register'
			});

			return model;
		}])
		.service('AffiliateRegistrar', ['config', 'Resource', 'Upload', function (config, Resource, Upload) {
			var model = new Resource({
				url: config.base + '/register/affiliate'
			});

			model.register = function (user, files, application_id, pardot = null) {
				return Upload.upload({
					url: this.options.url + '/2',
					method: 'POST',
					file: files,
					fileFormDataName: 'images[]',
					fields: {
						user: user,
						application_id: application_id,
                        pardot: pardot,
                        agency_user: user.agency_user
					},
					sendFieldsAs: 'form'
				});
			};

			model.continue = function (data) {
				return this.request({
					url: config.base + "/continue-application" ,
					method: "POST",
					data: data
				});
			};

			return model;
		}])
        .service('AdvertiserRegistrar', ['config', 'Resource', 'Upload', function (config, Resource, Upload) {
            var model = new Resource({
                url: config.base + '/register/advertiser'
            });
            model.register = function (user, files) {
                return Upload.upload({
                    url: this.options.url,
                    method: 'POST',
                    file: files,
                    fileFormDataName: 'images[]',
                    fields: user,
                    sendFieldsAs: 'form'
                });
            };

            return model;
        }])
        .service('AdperioAffiliateRegistrar', ['config', 'Resource', 'Upload', function (config, Resource, Upload) {
            var model = new Resource({
                url: config.base + '/register/adperio/publisher'
            });
            model.register = function (user) {
                return Upload.upload({
                    url: this.options.url,
                    method: 'POST',
                    fields: user,
                    sendFieldsAs: 'form'
                });
            };

            return model;
        }])
		.service('Contact', ['Resource', function (Resource) {
			var model = new Resource({
				url: config.base + '/contact'
			});

			return model;
		}])
		.service('Newsletter', ['Resource', function (Resource) {
			var model = new Resource({
				url: config.base + '/newsletter'
			});

			return model;
		}]);
})();
